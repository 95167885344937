<script setup lang="ts">
import type { Promotion } from "@/types";
const isGuest = useIsGuest();
const { t } = useT();

defineProps<{
	title: string;
	type?: Promotion["type"];
	image: string;
}>();

const handleClick = () => {
	navigateTo("/vip");
};
</script>

<template>
	<MPromotionWrapper
		:type="type"
		:title="title"
		:image="image"
		:buttonName="isGuest ? t('Register & join') : t('Play to Level Up!')"
		@click-card="handleClick"
	>
		<AText :size="22" class="subtitle" :modifiers="['bold', 'uppercase', 'center']">
			{{ t("Ready to take your gaming experience to the next level?") }}
		</AText>
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.subtitle {
	padding: 0 16px;
}
</style>
